<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <ion-slides>
        <ion-slide class="custom-ion-slide">
          <div class="initial">
            <img src="@/img/chuteira-bola.svg" />
          </div>
          <img src="@/img/swipe.gif" style="width: 30%; margin: 0" />
          <div class="container-information">
            <h2>Bem vindo</h2>
            <p>
              Mantenha-se informado das atualizações do futebol do seu grupo de
              amigos, visualize o ranking, jogadores destaques, perfis e lances
              de partida a medida que estes acontecem.
            </p>
          </div>
        </ion-slide>

        <ion-slide class="custom-ion-slide">
          <img
            style="border-radius: 50%; border: 7px solid white"
            src="@/img/FutebolDeRua.jpg"
          />
          <div class="container-information">
            <h2>Mantenha a motivação</h2>
            <p>
              Ganhe jogos, se destaque entre os seus amigos e veja como a sua
              pontuação no ranking aumenta à medida que você se dedita ao
              futebol
            </p>
          </div>
        </ion-slide>

        <ion-slide class="custom-ion-slide">
          <img
            style="border-radius: 50%; border: 7px solid white"
            src="@/img/FutebolDeRua2.jpg"
          />
          <div class="container-information">
            <h2>Receba atualizações imediatas</h2>
            <p>
              Saiba imediatamente os resultados e os lances das partidas
              conforme estes acontecem.
            </p>
          </div>
        </ion-slide>

        <ion-slide class="custom-ion-slide">
          <div class="loading">
            <img src="@/img/loading.svg" />
          </div>
          <div class="container-information" style="padding: 20px">
            <h2>Pronto para jogar?</h2>
            <ion-button fill="clear" @click="goToLogin()"
              >Continuar <ion-icon slot="end" :icon="arrowForward"></ion-icon
            ></ion-button>
          </div>
        </ion-slide>
      </ion-slides>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent } from "@ionic/vue";
import { arrowForward } from "ionicons/icons";
import { useRouter } from "vue-router";

export default {
  name: "Prelude",
  components: {
    IonPage,
    IonContent,
  },
  setup() {
    const router = useRouter();
    return {
      router,
    };
  },
  computed: {
    arrowForward() {
      return arrowForward;
    },
  },
  mounted() {
    const prelude = localStorage.getItem("prelude");
    if (prelude) {
      this.router.replace("/Login");
    }
  },
  methods: {
    async goToLogin() {
      localStorage.setItem("prelude", true);
      this.router.replace("/Login");
    },
  },
};
</script>

<style scoped>
@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

ion-content {
  --ion-background-color: #f5f7f9;
}

.custom-ion-slide {
  display: flex !important;
  flex-direction: column;
  justify-content: space-around;
}

.initial {
  width: 100%;
}

.loading {
  top: 0;
  right: 0;
  z-index: 999;
}

.loading img {
  width: 100%;
  -webkit-animation: rotating 0.8s infinite linear;
  -moz-animation: rotating 0.8s infinite linear;
  -ms-animation: rotating 0.8s infinite linear;
  -o-animation: rotating 0.8s infinite linear;
  animation: rotating 0.8s infinite linear;
}

ion-slides {
  height: 100%;
}

.swiper-slide {
  display: block;
}

.container-information {
  background-color: white;
  border-radius: 10px;
  margin: 30px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px,
    rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
}

.container-information h2 {
  color: black;
}

.swiper-slide h2 {
  /* margin-top: 2.8rem; */
}

.swiper-slide img {
  max-height: 50%;
  max-width: 80%;
  margin: 60px 0 40px;
  pointer-events: none;
}

b {
  font-weight: 500;
}

p {
  padding: 0 40px;
  font-size: 14px;
  line-height: 1.5;
  color: var(--ion-color-step-600, #60646b);
}

p b {
  color: var(--ion-text-color, #000000);
}

ion-button {
  font-weight: bold;
}
</style>
